.billing-box {
    background: var(--tertiary-color);
    border: 1px solid #ecf0f3;
    box-sizing: border-box;
    box-shadow: 0px 4px 32px rgba(130, 130, 130, 0.14);
    border-radius: 0px 30px;
    padding: 3em;
    height: 100%;
    position: relative;
    z-index: 2;
    font-family: "Roboto", sans-serif;
}

.billing-box .billing-info {
    background: #ffffff;
    border: 1px solid #ecf0f3;
    box-sizing: border-box;
    box-shadow: 0px 4px 30px rgba(130, 130, 130, 0.14);
    padding: 1em;
    border-radius: 0px 20px;
    margin-bottom: 1em;
}

.billing-box .billing-info:last-child{
    margin-bottom: 0;
}

.billing-box .billing-info h5 {
    font-size: 1.3em;
    font-weight: 400;
    color: #292935;
    letter-spacing: 0.002em;
    padding-top: 0.5em;
}

.billing-box .billing-info h6{
    font-size: 1.3em;
    font-weight: 600;
    color: #292935;
    letter-spacing: 0.002em;
    padding-top: 0.5em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.billing-box .billing-info .p-dots {
    padding-right: 1em;
}

.billing-action-menu-sec {
    margin-bottom: 3em;
}

.billing-action-menu-sec .billing-action-menu{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.billing-action-menu-sec .billing-action-menu li {
    margin-right: 1em;
}

.billing-action-menu-sec .billing-action-menu li:last-child{
    margin-right: 0;
}

.billing-action-menu-sec .billing-action-menu li a{
    border: 1px solid var(--secondary-color);
    padding: 0.8em 2em;
    font-size: 1.25em;
    font-weight: 600;
    color: var(--secondary-color);
    border-radius: 0px 20px;
}

.billing-account-img {
    padding-top: 3em;
}

/*Billing Account List Page CSS*/

.billing-account-center-table .billing-list-tabel {
    border-collapse: separate;
    border-spacing: 0 15px;
}

.billing-account-center-table  .billing-list-tabel tbody tr {
    background: #FFFFFF;
    border-top: 1px solid #C4C4C4;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    border-radius: 0px 20px;
    color: #292935;
}

.billing-account-center-table  .billing-list-tabel th {
    color: #828282;
    font-size: 1.2em;
    font-weight: 500;
    letter-spacing: 0.002em;
    font-family: 'Roboto', sans-serif;
    border-bottom: 1px solid #E2E2FA;
    /* text-align: center; */
}

.billing-account-center-table  .billing-list-tabel tbody td {
    border-top: 1px solid #C4C4C4;
    vertical-align: middle;
    /* text-align: center; */
    font-size: 1.1em;
    font-weight: 500;
    letter-spacing: 0.002em;
    font-family: 'Roboto', sans-serif;
    padding:1rem;
}

.billing-account-center-table  .billing-list-tabel tbody td:first-child {
    border-bottom-left-radius: 20px;
    border-left: 1px solid #C4C4C4;
}

.billing-account-center-table  .billing-list-tabel tbody td:last-child {
    border-top-right-radius: 20px;
    border-right: 1px solid #C4C4C4;
}

.billing-action-btn {
    display: flex;
    align-items: center;
}

.billing-action-btn .billing-edit {  
    width: 20px;
    height: 20px;
}

.billing-action-btn .billing-delete {
    width: 20px;
    height: 20px;
}

.badge-default {
    background: var(--primary-color);
    color: var(--tertiary-color);
    padding: 0.5em 1em;
    font-size: 0.9em;
    font-weight: 500;
}

.badge-default1 {
    background: var(--secondary-color);
    color: var(--tertiary-color);
    padding: 0.5em 1em;
    font-size: 0.9em;
    font-weight: 500;
}

.billing-account-list-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 2em;
}